import ENV from "@env/index"
import { Armazem } from "@interfaces/Armazem"
import { UserData } from "@modules/auth/interfaces/UserData"
import getNroDocumento from "@modules/auth/scripts/getNroDocumento"
import { DispatchType } from "@redux/types"
import { jsonToParams } from "@utils/jsonToParams"
import request from "@utils/request"
import { handleResponse } from "@utils/response"
import { ResponsePattern } from "@utils/response/types"
import { Dispatch } from "react"
import { ArmazemBloqueio } from "../interfaces/ArmazemBloqueio"
import formEditar from "../pages/ArmazemEditar/components/FormEdit/config"
import { resetSelectedArmazens, setArmazemBloqueios, setOpenModalBloquear, setOpenModalDesbloquear } from "../pages/ArmazemEditar/reducers/armazenEditarReducer"
import { requestGetArmazemBloqueiosReducers, requestSetArmazemBloqueioReducers, requestSetArmazemDesbloqueioReducers } from "../pages/ArmazemEditar/reducers/requestArmazemEditarReducer"
import { setArmazem, setArmazens } from "../reducers/armazemReducer"
import { requestCreateArmazemReducers, requestEditArmazemReducers, requestGetArmazemReducers, requestGetArmazensReducers, requestRemoveArmazemReducers } from "../reducers/requestArmazemReducer"
import { BloqueioValues } from "./types"

export async function getArmazem(dispatch: DispatchType, guid: string, userData: UserData){
    dispatch(requestGetArmazemReducers.reset())
    dispatch(requestGetArmazemReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazem/Carregar`
    const params = `?${jsonToParams({guid, ...getNroDocumento(userData)})}`
    const response = await request.get<ResponsePattern<Armazem[]>>({ endpoint, params })

    handleResponse('getArmazem', dispatch, response, requestGetArmazemReducers)
    .then(data => dispatch(setArmazem(data[0])))
}

export async function getArmazens(dispatch: DispatchType, userData: UserData){
    dispatch(requestGetArmazensReducers.reset())
    dispatch(requestGetArmazensReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazem/Listar`
    const params = `?${jsonToParams(getNroDocumento(userData))}`
    const response = await request.get<ResponsePattern<Armazem[]>>({ endpoint, params })

    handleResponse('getArmazens', dispatch, response, requestGetArmazensReducers)
    .then(data => dispatch(setArmazens(data)))
}

export async function createArmazem(dispatch: DispatchType, values: typeof formEditar.values, setRedirect: Dispatch<React.SetStateAction<boolean>>, userData: UserData){
    dispatch(requestCreateArmazemReducers.reset())
    dispatch(requestCreateArmazemReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazem/Salvar`
    const body = { ...values, ...getNroDocumento(userData) }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('createArmazem', dispatch, response, requestCreateArmazemReducers, options)
    .then(() => setRedirect(true))
}

export async function editArmazem(dispatch: DispatchType, values: typeof formEditar.values, guid: string, setRedirect: Dispatch<React.SetStateAction<boolean>>, userData: UserData){
    dispatch(requestEditArmazemReducers.reset())
    dispatch(requestEditArmazemReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazem/Salvar`
    const body = { ...values, guid, ...getNroDocumento(userData) }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('editArmazem', dispatch, response, requestEditArmazemReducers, options)
    .then(() => setRedirect(true))
}

export async function removeArmazem(dispatch: DispatchType, guid: string, setRedirect: Dispatch<React.SetStateAction<boolean>>, userData: UserData){
    dispatch(requestRemoveArmazemReducers.reset())
    dispatch(requestRemoveArmazemReducers.loading())

    const options = {messages: {errorMessage: {}, successMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazem/Excluir`
    const body = { guid, ...getNroDocumento(userData) }
    const response = await request.post<ResponsePattern<any>>({ endpoint, body })

    handleResponse('removeArmazem', dispatch, response, requestRemoveArmazemReducers, options)
    .then(() => setRedirect(true))
}

export async function getBloqueios(dispatch: DispatchType, guid: string, data: string, userData: UserData){
    dispatch(requestGetArmazemBloqueiosReducers.reset())
    dispatch(requestGetArmazemBloqueiosReducers.loading())

    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazemBloqueio/Listar`
    const params = `/?${jsonToParams({guid, data, ...getNroDocumento(userData)})}`
    const response = await request.get<ResponsePattern<ArmazemBloqueio[]>>({ endpoint, params })

    handleResponse('getBloqueios', dispatch, response, requestGetArmazemBloqueiosReducers)
    .then(data => dispatch(setArmazemBloqueios(data)))
}

export async function setBloqueio(dispatch: DispatchType, guid: string, data: string, values: BloqueioValues | null, userData: UserData){
    dispatch(requestSetArmazemBloqueioReducers.reset())
    dispatch(requestSetArmazemBloqueioReducers.loading())

    const paramsObj = { guid, data }
    if(!!values?.horario) Object.assign(paramsObj, {horario: values.horario})
    if(!!values?.nroDoca) Object.assign(paramsObj, {nroDoca: values.nroDoca})

    const options = {messages: {errorMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazemBloqueio/Bloquear`
    const params = `/?${jsonToParams({...paramsObj, ...getNroDocumento(userData)})}`
    const response = await request.get<ResponsePattern<any>>({ endpoint, params })

    handleResponse('setBloqueio', dispatch, response, requestSetArmazemBloqueioReducers, options)
    .then(() => {
        dispatch(resetSelectedArmazens())
        dispatch(setOpenModalBloquear(false))
        getBloqueios(dispatch, guid, data, userData)
    })
}

export async function setDesbloqueio(dispatch: DispatchType, guid: string, data: string, values: BloqueioValues | null, userData: UserData){
    dispatch(requestSetArmazemDesbloqueioReducers.reset())
    dispatch(requestSetArmazemDesbloqueioReducers.loading())

    const paramsObj = { guid, data }
    if(!!values?.horario) Object.assign(paramsObj, {horario: values.horario})
    if(!!values?.nroDoca) Object.assign(paramsObj, {nroDoca: values.nroDoca})

    const options = {messages: {errorMessage: {}}}
    const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazemBloqueio/Desbloquear`
    const params = `/?${jsonToParams({...paramsObj, ...getNroDocumento(userData)})}`
    const response = await request.get<ResponsePattern<any>>({ endpoint, params })

    handleResponse('setDesbloqueio', dispatch, response, requestSetArmazemDesbloqueioReducers, options)
    .then(() => {
        dispatch(resetSelectedArmazens())
        dispatch(setOpenModalDesbloquear(false))
        getBloqueios(dispatch, guid, data, userData)
    })
}

export async function setMultipleBloqueio(dispatch: DispatchType, guid: string, data: string, bloqueios: BloqueioValues[], userData: UserData){
    dispatch(requestSetArmazemBloqueioReducers.reset())
    dispatch(requestSetArmazemBloqueioReducers.loading())

    Promise.all(bloqueios.map(async item => {
        const paramsObj = { guid, data }
        Object.assign(paramsObj, item)

        const options = {messages: {errorMessage: {}}}
        const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazemBloqueio/Bloquear`
        const params = `/?${jsonToParams({...paramsObj, nroDocumentoVinculo: userData.nroDocumento ?? '', ...getNroDocumento(userData)})}`
        const response = await request.get<ResponsePattern<any>>({ endpoint, params })

        handleResponse('setMultipleBloqueio', dispatch, response, requestSetArmazemBloqueioReducers, options)
    }))
    .then(() => {
        dispatch(resetSelectedArmazens())
        dispatch(setOpenModalBloquear(false))
        getBloqueios(dispatch, guid, data, userData)
    })
}

export async function setMultipleDesbloqueio(dispatch: DispatchType, guid: string, data: string, desbloqueios: BloqueioValues[], userData: UserData){
    dispatch(requestSetArmazemDesbloqueioReducers.reset())
    dispatch(requestSetArmazemDesbloqueioReducers.loading())

    Promise.all(desbloqueios.map(async item => {
        const paramsObj = { guid, data }
        Object.assign(paramsObj, item)

        const options = {messages: {errorMessage: {}}}
        const endpoint = `${ENV.COMANDO_SUPPLY_ENDPOINT}/Api/Agendamentos/GestaoArmazemBloqueio/Desbloquear`
        const params = `/?${jsonToParams({...paramsObj, nroDocumentoVinculo: userData.nroDocumento ?? '', ...getNroDocumento(userData)})}`
        const response = await request.get<ResponsePattern<any>>({ endpoint, params })

        handleResponse('setMultipleDesbloqueio', dispatch, response, requestSetArmazemDesbloqueioReducers, options)
    }))
    .then(() => {
        dispatch(resetSelectedArmazens())
        dispatch(setOpenModalDesbloquear(false))
        getBloqueios(dispatch, guid, data, userData)
    })
}