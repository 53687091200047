import React from 'react'
import { useAppSelector } from '@redux/hooks'
import numberedArray from '@utils/numberedArray'
import createClassName from '@utils/createClassName'
import minutesInterval from '@utils/minutesInterval'
import conditionalRender from '@utils/conditionalRender'
import PainelBox from '../PainelBox'

const Painel: React.FC = () => {

    const { userData } = useAppSelector(s => s.auth)
    const { armazens } = useAppSelector(s => s.armazem)
    const { armazemBloqueios } = useAppSelector(s => s.armazemEditar)
    const { agendamentos, currentArmazem } = useAppSelector(s => s.agendamento)
    const { requestGetAgendamentos } = useAppSelector(s => s.requestAgendamento)

    const CR = conditionalRender(requestGetAgendamentos, agendamentos)

    return(

        <>
            {(CR.DATA && !!agendamentos && !!armazens) && (
                <div className = "tableContainer custom scroll mt-6">
                    <table className = "w-full custom">
                        <thead className = "flex">
                            <tr className = "flex w-full">
                                <th className = "w-[80px]">Hora</th>
                                {numberedArray(armazens.find(f => f.id === currentArmazem!)!.qtdeDocas, false).map((dock, index) => (
                                    <th key = {index} className = "flex-1 min-w-[246px]">{`Janela ${dock}`}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className = "flex flex-col items-center justify-between overflow-scroll w-full max-h-[50vh] overlayScroll">
                            {minutesInterval(armazens.find(f => f.id=== currentArmazem!)!.intervalo === '01:00' ? 60 : 30).map((hour, index) => (
                                <tr key = {index} className = "flex w-full">
                                    <td className = "w-[80px] flex items-center justify-center text-center">{hour}</td>
                                    {numberedArray(armazens.find(f => f.id === currentArmazem!)!.qtdeDocas, false).map((dock, dockIndex) => {
                                        const findInBloqueios = !!armazemBloqueios?.find(({ horario, nroDoca, nroDocumentoVinculo }) => (!!nroDocumentoVinculo ? nroDocumentoVinculo !== userData!.nroDocumento : true) && (`h${horario}d${nroDoca}` === `h${hour}d${dock}`))

                                        return(
                                            <td
                                               key = {dockIndex} 
                                               id = {`h${hour}d${dock}`}
                                               className = {createClassName("flex-1 min-w-[246px] flex flex-row gap-2 flex-wrap", [findInBloqueios && "bg-red-500"])}
                                               data-hour = {hour}
                                               data-dock = {dock}
                                           >
                                               {agendamentos.filter(({ hora, nrDoca }) => `h${hora}d${nrDoca}` === `h${hour}d${dock}`).map((item, indexBox) => (
                                                   <PainelBox
                                                       key = {indexBox}
                                                       {...item}
                                                   />
                                               ))}
                                           </td>
                                       )
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </>

    )

}

export default Painel