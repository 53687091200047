import React, { useState } from 'react'
import { Navigate } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik'
import Form from '@components/Form'
import Button from '@components/Button'
import Select from '@components/Select'
import ButtonGroup from '@components/Button/Group'
import selectIntervalos from '@constants/select/intervalo'
import { createArmazem } from '@modules/armazem/controllers'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import formCreate from './config'

const FormCreate: React.FC = () => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const [redirect, setRedirect] = useState(false)

    return(

        <>
            {redirect && <Navigate to = "/armazem" />}
            <Formik
                initialValues = {formCreate.values}
                validationSchema = {formCreate.schema}
                onSubmit = {v => createArmazem(dispatch, v, setRedirect, userData!)}
            >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                    <FormikForm>
                        <Form.Container padding = {false}>
                            <Form.Section>
                                <Form.Row columns = {4}>
                                    <Form.Group
                                        inputID = "descricao"
                                        inputName = "descricao"
                                        error = {!!errors.descricao}
                                        label = "Descrição"
                                    />
                                    <Select
                                        type = "outline"
                                        label = "Intervalo"
                                        value = {values.intervalo}
                                        error = {!!errors.intervalo}
                                        field = "intervalo"
                                        setFieldValue = {setFieldValue}
                                        items = {selectIntervalos}
                                    />
                                    <Form.Group
                                        inputID = "qtdeDocas"
                                        inputName = "qtdeDocas"
                                        inputType = "number"
                                        error = {!!errors.qtdeDocas}
                                        label = "Quantidade de janelas"
                                    />
                                    <Form.Group
                                        inputID = "qtdeLimiteAgendamentosDia"
                                        inputName = "qtdeLimiteAgendamentosDia"
                                        inputType = "number"
                                        error = {!!errors.qtdeLimiteAgendamentosDia}
                                        label = "Limite diário de agendamentos por placa"
                                    />
                                </Form.Row>
                                <Form.Row columns = {2}>
                                    <Form.Group
                                        inputID = "endereco"
                                        inputName = "endereco"
                                        error = {!!errors.endereco}
                                        label = "Endereço"
                                    />
                                    <Form.Group
                                        inputID = "listaEmail"
                                        inputName = "listaEmail"
                                        error = {!!errors.listaEmail}
                                        label = "Emails"
                                    />
                                </Form.Row>
                            </Form.Section>
                            <Form.Section title = "Horário de atendimento">
                                <Form.Row grid = "1fr auto 1fr 1fr auto 1fr" columns = {4}>
                                    <Form.Group
                                        label = "Manhã entrada"
                                        error = {!!errors.atendimentoManhaEntrada}
                                        inputID = "atendimentoManhaEntrada"
                                        inputName = "atendimentoManhaEntrada"
                                        inputType = "time"
                                    />
                                    <span className = "fieldHelper">às</span>
                                    <Form.Group
                                        label = ""
                                        error = {!!errors.atendimentoManhaSaida}
                                        inputID = "atendimentoManhaSaida"
                                        inputName = "atendimentoManhaSaida"
                                        inputType = "time"
                                    />
                                    <Form.Group
                                        label = "Tarde entrada"
                                        error = {!!errors.atendimentoTardeEntrada}
                                        inputID = "atendimentoTardeEntrada"
                                        inputName = "atendimentoTardeEntrada"
                                        inputType = "time"
                                    />
                                    <span className = "fieldHelper">às</span>
                                    <Form.Group
                                        label = ""
                                        error = {!!errors.atendimentoTardeSaida}
                                        inputID = "atendimentoTardeSaida"
                                        inputName = "atendimentoTardeSaida"
                                        inputType = "time"
                                    />
                                </Form.Row>
                            </Form.Section>
                            <ButtonGroup>
                                <Button
                                    label = "Criar"
                                    status = "success"
                                    onClick = {handleSubmit}
                                />
                            </ButtonGroup>
                        </Form.Container>
                    </FormikForm>
                )}
            </Formik>
        </>

    )

}

export default FormCreate