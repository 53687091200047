import React from 'react'
import { Form as FormikForm, Formik } from 'formik'
import Form from '@components/Form'
import Modal from '@components/Modal'
import Button from '@components/Button'
import Select from '@components/Select'
import ModalActions from '@components/Modal/Actions'
import { statusItems } from '@constants/selects'
import { ModalProps } from '@interfaces/Modal'
import { editAcessoUsuario } from '@modules/acesso/controllers/usuarioController'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import createSelectItems from '@utils/createSelectItems'

const ModalEdit: React.FC <ModalProps> = props => {

    const dispatch = useAppDispatch()
    const { userData } = useAppSelector(s => s.auth)
    const { acessoGrupos } = useAppSelector(s => s.acessoGrupo)
    const { currentAcessoUsuario } = useAppSelector(s => s.acessoUsuario)
    const { requestUpdateUsuario } = useAppSelector(s => s.requestAcessoUsuario)

    const idGrupoAcesso = currentAcessoUsuario?.grupoAcesso?.id ? currentAcessoUsuario.grupoAcesso.id : null

    return(

        <>
            {!!currentAcessoUsuario && (
                <Modal {...props} title = "Editar usuário">
                    <Formik
                        initialValues = {{
                            idGrupoAcesso,
                            id: currentAcessoUsuario.id,
                            idUsuarioAlteracao: userData!.id,
                            nome: currentAcessoUsuario.nome,
                            email: currentAcessoUsuario.email,
                            telefone: currentAcessoUsuario.telefone,
                            login: currentAcessoUsuario.login,
                            ativo: currentAcessoUsuario.ativo,
                            documento: currentAcessoUsuario.nroDocumento,
                            senha: null,
                        }}
                        onSubmit = {v => editAcessoUsuario(dispatch, userData!, v, props.setOpen)}
                    >
                        {({ setFieldValue, handleChange, values }) => (
                            <FormikForm>
                                <Form.Container padding = {false}>
                                    <Form.Row columns = {1}>
                                        <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                    </Form.Row>
                                    <Form.Row columns = {1}>
                                        <Form.Group inputID = "documento" inputName = "documento" label = "Documento" />
                                    </Form.Row>
                                    <Form.Row columns = {2}>
                                        <Form.Group inputID = "email" inputName = "email" inputType = "email" label = "E-mail (Opcional)" />
                                        <Form.Group
                                            inputID = "telefone"
                                            inputName = "telefone"
                                            label = "Telefone"
                                            mask = {['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            onChange = {handleChange}
                                        />
                                    </Form.Row>
                                    <Form.Row columns = {2}>
                                        <Form.Group inputID = "login" inputName = "login" label = "Login" />
                                        <Form.Group inputID = "senha" inputName = "senha" inputType = "password" label = "Senha" />
                                    </Form.Row>
                                    <Form.Row columns = {2}>
                                        <Select
                                            type = "outline"
                                            label = "Situação"
                                            field = "ativo"
                                            value = {values.ativo}
                                            setFieldValue = {setFieldValue}
                                            items = {statusItems}
                                        />
                                        <Select
                                            type = "outline"
                                            label = "Grupo de Acesso"
                                            field = "idGrupoAcesso"
                                            value = {values.idGrupoAcesso}
                                            setFieldValue = {setFieldValue}
                                            items = {createSelectItems(acessoGrupos, 'nome', 'id')}
                                        />
                                    </Form.Row>
                                    <ModalActions>
                                        <Button
                                            type = "reset"
                                            label = "Cancelar"
                                            onClick = {() => props.setOpen(false)}
                                        />
                                        <Button
                                            type = "submit"
                                            label = "Editar"
                                            loading = {requestUpdateUsuario.loading}
                                            status = "success"
                                        />
                                    </ModalActions>
                                </Form.Container>
                            </FormikForm>
                        )}
                    </Formik>
                </Modal>
            )}
        </>
        
    )

}

export default ModalEdit